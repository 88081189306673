import {IRoutesConfig} from '@casecommons/cbp-core-typescript'
import {lazy} from 'react'

export const HOME_ROUTE_KEY = 'HOME'
export const TERMS_OF_SERVICE_ROUTE_KEY = 'TOS'
export const PROFILE_ROUTE_KEY = 'PROFILE'
export const SEARCH_ROUTE_KEY = 'SEARCH'
export const NOTEBOOK_ROUTE_KEY = 'NOTEBOOK'
export const MEETINGS_ROUTE_KEY = 'MEETINGS'
export const SERVICES_ROUTE_KEY = 'SERVICES'
export const MULTIFACTOR_AUTH_ROUTE_KEY = 'MFA'

export const NOTEBOOK_TITLE_VARIABLE = 'notebookName'
export const NOTEBOOK_RESOURCE_TITLE_VARIABLE = 'resourceTitle'
export const MEETINGS_RESOURCE_NAME_VARIABLE = 'resourceName'

const config: IRoutesConfig = {
  routesConfig: [
    {
      Component: lazy(() => import('./pages/Home')),
      childRoutes: [
        {
          Component: lazy(() => import('./pages/Notebook')),
          id: NOTEBOOK_ROUTE_KEY,
          path: '/notes/:resource_type/:resource_id',
          title: `$${NOTEBOOK_RESOURCE_TITLE_VARIABLE}`,
        },
        {
          Component: lazy(() => import('./pages/Meetings')),
          id: MEETINGS_ROUTE_KEY,
          path: '/meetings',
          title: `$${MEETINGS_RESOURCE_NAME_VARIABLE}`,
        },
        {
          Component: lazy(() => import('./pages/Profile')),
          id: PROFILE_ROUTE_KEY,
          path: '/profile',
          title: 'My Profile',
        },
        {
          Component: lazy(() => import('./pages/TermsOfService')),
          id: TERMS_OF_SERVICE_ROUTE_KEY,
          path: '/terms-of-service',
          title: 'Terms of service',
        },
        {
          Component: lazy(() => import('./pages/MultifactorAuth')),
          id: MULTIFACTOR_AUTH_ROUTE_KEY,
          path: '/mfa-setup',
          title: 'Multi-Factor authentication',
        },
        {
          Component: lazy(() => import('./pages/Search')),
          id: SEARCH_ROUTE_KEY,
          path: '/search',
          title: 'Search',
        },
      ],
      default: true,
      id: HOME_ROUTE_KEY,
      path: '/',
      title: 'Home',
    },
  ],
}

export default config
