import {UserContext} from '@casecommons/cbp-react-auth'
import React from 'react'

export default () => {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserContext')
  }
  return context.user
}
